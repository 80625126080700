<template>
  <base-section
    id="features"
    class="secondary text-center"
  >
  <span v-intersect ="handleIntersect"></span>
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
       <!--     <v-img class="center-image"
              src="../../assets/incognit/MicrosoftTeams-image.png"
              max-width="150px"
            ></v-img> -->
              <v-img class="center-image"
              src="../../assets/incognit/MicrosoftTeams-image_white.png"
              max-width="100px"
            ></v-img>
    <v-container fluid>
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="12"
            md="12"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
import { EventBus } from '../../event-bus';
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          //icon: 'mdi-keyboard-outline',
          title: 'ServiceNow Partner',
          text: 'Imagine a start-up full of talented people with customer acclaimed #ServiceNow expertise, flat hierarchy, transparent culture and a cooperative vision… That’s us, at #Incognit.cloud! A ServiceNow Specialist Partner, proudly located in #Fundao, in the interior of Portugal.',
        },
      ],
    }),
    methods: {
      handleIntersect(entries, observer) {
			if(entries[0].isIntersecting) {
        EventBus.$emit('changeTab', "#features" );
			}
		}
    }
  }
</script>
